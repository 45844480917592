<template>
  <div class="friendCirclEdit page">
    <div class="s1 p-card fs-16 m-3">
      <div class="flex h-between p-3 pointer"
           @click="handleReset">
        <div>发布内容</div>
        <button class="button reset-button fs-14">重置</button>
      </div>
    </div>

    <div class="s1 m-3">
      <div class="wrap">
        <div class="textarea px-3">
          <template v-if="[2017, 2019].includes(fromData.msgType)">
                <textarea v-model="fromData.linkDesc"
                          maxlength="300"
                          placeholder="请输入"/>
            <p>{{ fromData.linkDesc.length }}/300</p>
          </template>
          <template v-else>
               <textarea v-model="fromData.content"
                         maxlength="300"
                         placeholder="请输入"/>
            <p>{{ fromData.content.length }}/300</p>
          </template>

        </div>
        <van-grid v-if="fromData.msgType === 2002"
                  :column-num="3"
                  :border="false">
          <van-grid-item v-for="(src, index) in fromData.picUrl"
                         :key='index'>
            <van-image :src="src"
                       fit="scale-down"/>
            <van-icon @click="removeImgItem(src)"
                      name="clear"/>
          </van-grid-item>
          <van-grid-item v-if="fromData.picUrl.length < 9">
            <div class="addImg"
                 @click="inputClick">
              <van-icon name="plus"/>
              <input ref="imgInput"
                     class="file"
                     type="file"
                     @change="addImgList">
            </div>
          </van-grid-item>
        </van-grid>
      </div>
    </div>

    <div class="p-footer h-end">
      <div class="flex  flex-right">
        <button class="btn auto"
                @click="$router.go(-1)">取消
        </button>
        <button class="btn auto fill ml-3"
                @click="submit">保存
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import msgBox from '@/components/msg-box/index.vue'
import {mapGetters} from 'vuex'
import {ossUploadFile} from '@/utils/index'
import {ApiGetPushMsgDetail, ApiResetEditPushMsg, ApiSaveFriendCirclePushMsg} from '../../api/FriendCircle'
import {Notify, Toast} from 'vant'

export default {
  name: 'KeyWords',
  components: {
    msgBox
  },
  data() {
    return {

      dialog: false,
      dialog_pick: '纯文字',
      actions: [{
        name: '纯文字',
        value: 2001
      },
        {
          name: '文字+图片',
          value: 2002
        },
        {
          name: '文字+链接',
          value: 2005
        }
      ],
      dialog1: false,

      fromData: {
        msgId: 0,
        msgType: 2001, // 类型 2001文字 2002图文 2004视频 2005链接
        content: '',
        picUrl: [],
        linkDesc: '',
        linkPic: '',
        linkUrl: ''
      }
    }
  },
  computed: {
    ...mapGetters(['contentData', 'globalData', 'robotInfo'])
  },
  created() {
    this.getPushMsgDetails()
  },
  methods: {
    async getPushMsgDetails() {
      try {
        this.fromData = await ApiGetPushMsgDetail(this.$route.query.msgId)
        this.fromData.picUrl = this.fromData.picUrl ? this.fromData.picUrl.split(',') : []
      } catch (e) {
        console.warn(e)
      }
    },
    uploadImg(e) {
      if (e.target.files.length) {
        const file = e.target.files[0]
        const isImage = file.type.indexOf('image') > -1
        if (!isImage) {
          this.$Toast('请选择正确的图片格式！')
          return
        }
        ossUploadFile(file)
            .then(res => {
              if (res) {
                this.fromData.linkPic = res
              }
            })
      }
    },
    async handleReset() {
      try {
        await ApiResetEditPushMsg(this.fromData.msgId)
        Notify({type: 'success', message: '重置成功!', duration: 1000});
        this.$router.back(-1)
      } catch (e) {
        console.log(e)
      }
    },

    inputClick() {
      this.$refs['imgInput'].click()
    },
    addImgList(e) {
      if (e.target.files.length) {
        const file = e.target.files[0]
        const isImage = file.type.indexOf('image') > -1
        if (!isImage) {
          this.$Toast('请选择正确的图片格式！')
          return
        }
        ossUploadFile(file)
            .then(res => {
              if (res) {
                this.fromData.picUrl.push(res)
              }
            })
      }
    },
    removeImgItem(src) {
      this.fromData.picUrl = this.fromData.picUrl.filter(obj => obj != src)
    },
    async submit() {
      const toast = Toast.loading({
        message: '数据保存中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
      try {
        await ApiSaveFriendCirclePushMsg({
          ...this.fromData,
          picUrl: this.fromData.picUrl.join()
        })
        toast.clear()
        Notify({
          type: 'success',
          message: '保存成功'
        })
        this.$router.go(-1)
      } catch (e) {
        toast.clear()
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  padding-bottom: px(50);

  .reset-button {
    width: 54px;
    height: 28px;
    border-radius: 14px;
    border: 1px solid #FF445E;
    font-weight: 500;
    color: #FF445E;
  }

  .s1 {
    .wrap {
      position: relative;
      background: #ffffff;
      box-shadow: 0px px(1) px(1) 0px rgba(0, 0, 0, 0.06);
      border-radius: px(4);
      color: #666;
      padding: px(15) 0;

      .textarea {
        textarea {
          width: 100%;
          height: 240px;
          line-height: px(24);
          box-sizing: border-box;
          font-size: px(16);
          border: 0;
          resize: none;
        }

        p {
          color: #ccc;
          text-align: right;
        }
      }

      .divider {
        height: 0;
        background: none;
        border-bottom: dashed px(1) #f1f1f1;
      }

      .inp {
        display: block;
        width: 100%;
        height: px(50);
        line-height: px(50);
        text-align: right;
        font-size: px(16);
        color: #3d3c43;
        border: 0;

        .input-placeholder {
          color: #a0a0a4;
          font-weight: inherit;
        }
      }

      .avatar {
        position: relative;
        width: px(36);
        height: px(36);

        .img {
          display: block;
          width: px(36);
          height: px(36);
          border-radius: px(2);
        }

        .del {
          position: absolute;
          top: 0;
          right: 0;
          width: px(18);
          height: px(18);
          background: url("./assets/icon-2.png") center no-repeat;
          background-size: contain;
          transform: translate(50%, -50%);
          border-radius: 50%;
        }

        &.add {
          position: relative;
          width: px(26);
          height: px(26);
          background: url("./assets/icon-1.png") center no-repeat;
          background-size: contain;
          overflow: hidden;

          .file {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
          }
        }
      }
    }
  }

}


.van-grid-item .van-icon-clear {
  position: absolute;
  top: 0;
  right: 0;
  color: #fe8d36;
}

.van-grid-item .addImg {
  width: px(90);
  height: px(90);
  text-align: center;

  i {
    margin-top: px(25);
    font-size: px(40);
  }

  input {
    display: none;
  }
}
</style>
<style lang="scss">
.friendCirclEdit .van-grid-item__content {
  position: relative;
  padding: px(5);

  .van-image {
    width: px(90);
    height: px(90);
  }
}
</style>
